<template>
  <div>
    <bread-crumb v-if="data" :selected="data.name"></bread-crumb>

    <div
      v-if="
        this.$route.params.children !== 'bezpeka-merezi' &&
        this.$route.params.children !== 'palo-alto' &&
        this.$route.params.children !== 'fortinet'
      "
    >
      <section class="informational-security container container__mini2">
        <h2 class="informational-security__title">{{ data.name }}</h2>
        <div class="informational-security__wrap">
          <div class="informational-security__wrap--img">
            <div class="informational-security__wrap--img--inner">
              <v-lazy-image
                :src="url + data.image"
                rel="preload"
                width="239"
                height="239"
                alt="image-logo"
              />
            </div>
          </div>
          <div class="informational-security__wrap--descr">
            <h3 class="informational-security__wrap--descr--title">
              <div v-if="data" v-html="data.description"></div>
            </h3>
          </div>
        </div>
        <div
          class="informational-security__wrap--descr--text"
          v-if="data"
          v-html="data.additional_description"
        ></div>
      </section>

      <section
        class="informational-security container container__mini2"
        v-if="
          data.additional_image &&
          data.description_1 &&
          data.additional_description_1
        "
      >
        <div class="informational-security__wrap">
          <div class="informational-security__wrap--img">
            <div class="informational-security__wrap--img--inner">
              <v-lazy-image
                :src="url + data.additional_image"
                rel="preload"
                width="239"
                height="239"
                alt="image-logo"
              />
            </div>
          </div>
          <div class="informational-security__wrap--descr">
            <h3 class="informational-security__wrap--descr--title">
              <div v-if="data" v-html="data.description_1"></div>
            </h3>
          </div>
        </div>
        <div
          class="informational-security__wrap--descr--text"
          v-if="data"
          v-html="data.additional_description_1"
        ></div>
      </section>
    </div>

    <!--   Безпека мережі   -->
    <div
      v-if="this.$route.params.children === 'bezpeka-merezi'"
      class="ip-telephony-security fs28 mb150"
    >
      <div
        class="ip-telephony-child__two container container__mini"
        v-if="childrens.length > 0"
      >
        <h2 class="informational-security__title">{{ data.name }}</h2>

        <div class="ip-telephony-child__two-wrap">
          <Cart
            v-for="(children, id) in childrens"
            :key="children.id"
            :data="children"
            :links="links(id)"
          />
        </div>
      </div>
    </div>

    <!--   Palo alto  || Fortinet -->
    <div
      v-if="
        this.$route.params.children === 'palo-alto' ||
        this.$route.params.children === 'fortinet'
      "
    >
      <section
        class="informational-security container container__mini2"
        v-if="data"
      >
        <h2 class="informational-security__title">{{ data.name }}</h2>

        <div class="informational-security__wrap reverse">
          <div class="informational-security__wrap--img">
            <div class="informational-security__wrap--img--inner">
              <v-lazy-image
                :src="url + data.image"
                rel="preload"
                width="628"
                height="314"
                alt="image-logo"
              />
            </div>
          </div>
          <div
            class="informational-security__wrap--descr"
            v-html="data.description"
          ></div>
        </div>

        <div class="informational-security__details details">
          <div class="details__left">
            <img
              src="~@/assets/images/display.svg"
              width="61"
              height="61"
              alt="display"
            />

            <div
              class="details__wrap"
              v-if="Object.keys(childrens).length !== 0"
            >
              <h3 class="details__wrap--title">{{ childrens[0].name }}</h3>
              <router-link tag="a" :to="data.slug + '/' + childrens[0].slug">{{
                t("more_details")
              }}</router-link>
            </div>
          </div>
          <div class="details__right">
            <img
              src="~@/assets/images/settings.svg"
              width="61"
              height="61"
              alt="display"
            />

            <div
              class="details__wrap"
              v-if="Object.keys(childrens).length !== 0"
            >
              <h3 class="details__wrap--title">{{ childrens[1].name }}</h3>
              <router-link tag="a" :to="data.slug + '/' + childrens[1].slug">{{
                t("more_details")
              }}</router-link>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import { defineAsyncComponent } from "vue";

const BreadCrumb = defineAsyncComponent(() =>
  import("@/components/BreadCrumb")
);
const Cart = defineAsyncComponent(() => import("@/components/Cart.vue"));
import VLazyImage from "v-lazy-image";
import { useMeta } from "vue-meta";
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import axios from "axios";

export default defineComponent({
  name: "InformationalSecurityZahist",
  setup() {
    const { t } = useI18n();
    useMeta({
      htmlAttrs: {
        lang: localStorage.getItem("VueAppLanguage"),
        amp: true,
      },
    });
    return {
      t,
    };
  },
  components: {
    Cart,
    BreadCrumb,
    VLazyImage,
  },
  props: {
    url: {
      type: String,
      default: process.env.VUE_APP_API,
    },
  },
  watch: {
    data: function (newData) {
      //проверка на пустоту объекта
      if (Object.keys(newData).length !== 0) {
        window.mitt.emit("childrenData", this.childrenData);
      }
    },
  },
  data() {
    return {
      locale: this.$i18n.locale,
      data: [],
      childrens: [],
      products: [],
      childrenData: {
        image: "",
        icon: "",
        title: "",
      },
    };
  },
  methods: {
    links(id) {
      return [
        {
          slug:
            this.childrens[id].slug + "/" + this.childrens[id].children[0].slug,
          title: this.childrens[id].children[0].name,
        },
        {
          slug:
            this.childrens[id].slug + "/" + this.childrens[id].children[1].slug,
          title: this.childrens[id].children[1].name,
        },
      ];
    },
    getTranslatedDescription(transArray, locale) {
      let description = "";
      transArray.forEach((element) => {
        if (element.locale === locale) {
          description = element.description;
        }
      });
      return description;
    },
    async getService() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/services/search/" +
            this.$route.params.children,
          config
        );
        this.data = response.data.data[0];
        this.childrens = response.data.data[0].children;
        this.products = response.data.data[0].products;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
    async getBanner() {
      try {
        const config = {
          headers: {
            "Content-Type": "application/json",
          },
        };
        const response = await axios.get(
          process.env.VUE_APP_API +
            "/api/" +
            this.locale +
            "/banners/search/" +
            this.$route.params.children,
          config
        );
        this.childrenData.image = response.data.data[0].image;
        this.childrenData.icon = response.data.data[0].icon[0].url;
        this.childrenData.title = response.data.data[0].title;
      } catch (e) {
        alert("Ошибка запросса");
      } finally {
        //что-то делаем после завершения
      }
    },
  },
  created() {
    this.getService();
    this.getBanner();
  },
  updated() {
    document.title = this.data.name + " - iIT Trading";
    window.mitt.emit("childrenData", this.childrenData);
  },
});
</script>
<style lang="scss">
@import "~@/assets/styles/_mixins.scss";

.informational-security {
  display: flex;
  flex-direction: column;
  @include property("margin-bottom", 150, 30);

  &__title {
    text-align: center;
    font-weight: 700;
    @include property("font-size", 42, 34);
    line-height: 1;
    color: #000000;
    text-transform: uppercase;
    @include property("margin-top", 40, 20);
    @include property("margin-bottom", 48, 25);
  }

  &__subtitle {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-weight: 700;
    @include property("font-size", 28, 21);
    line-height: calc(40 / 28);
    color: #f9b80e;
    @include property("margin-bottom", 60, 30);

    img,
    v-lazy-image {
      display: block;
      flex: 0 0 26px;
      margin-right: 15px;
    }
  }

  &__wrap {
    display: grid;
    @include property("gap", 65, 20, true, 1670, 1024);
    @include property("margin-bottom", 45, 15);
    justify-content: center;

    @media (min-width: 768px) {
      &:not(.reverse) {
        grid-template-columns: 1.5fr 1fr;
      }

      &.reverse {
        grid-template-columns: 1fr 1.5fr;
      }
    }

    &--img {
      width: 100%;
      align-self: flex-start;

      .reverse & {
        order: 2;
      }

      .informational-security__wrap:not(.reverse) & {
        background: url("~@/assets/images/bg-card.jpg") no-repeat;
        background-size: cover;
      }

      @media (max-width: 767.98px) {
        max-width: 500px;
        margin: 0 auto;
      }

      &--inner {
        position: relative;
        padding-bottom: 76%;
      }

      img {
        position: absolute;
        height: 100%;
        object-fit: contain;

        .service__wrap:not(.reverse) & {
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 50%;
          height: 50%;
          max-height: 239px;
          max-width: 239px;
        }

        .reverse & {
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }

    &--descr {
      display: flex;
      flex-direction: column;
      justify-content: center;

      &--title {
        @include property("font-size", 24, 21);
        letter-spacing: 0.48px;

        span {
          color: #f9b80e;
        }
      }

      &--text,
      p {
        @include property("font-size", 24, 20);
        letter-spacing: 0.48px;
        color: #1c1c1c;
      }
    }
  }
}

.details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 330px));
  @include property("gap", 85, 20, true, 1024, 768);
  justify-content: center;
  @include property("margin-top", 70, 30);

  > * {
    img {
      flex: 0 0 auto;
      display: block;
      width: 61px;
    }
  }

  &__wrap {
    margin-left: 30px;
    padding-left: 30px;
    border-left: 3px solid #f9b80e;

    &--title {
      @include property("font-size", 28, 21);
      margin-bottom: 20px;
      color: #1c1c1c;
    }

    a {
      font-size: 16px;
      line-height: calc(30 / 16);
      letter-spacing: 2.4px;
      color: #f9b80e;
    }
  }

  &__left {
    display: flex;
    align-items: flex-start;
  }

  &__right {
    display: flex;
    align-items: flex-start;
  }
}

.ip-telephony-security {
  .ip-telephony-child__two-wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-right: 20px;
  }
}
</style>
